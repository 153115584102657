export default {
    props:['themes', 'image', 'file', 'imgBckStatus', 'activeTheme'],
    data(){
        return{
            toggleImg: this.imgBckStatus,
            imageFile: this.image,
        }
    },
    methods:{
        choiceBckImg(){
            this.$emit('choiceBckImg', this.toggleImg)
        },
        choiceRadio(itemId){
            this.$emit('changeThemeId' +
                '', itemId)
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(files[0]);
            this.$emit('getFile', files[0])
        },
        createImage(fileOpen){
            // let image = new Image();
            let reader = new FileReader();
            let vm = this;

            reader.onload = (e) => {
                vm.imageFile = e.target.result;
            };
            reader.readAsDataURL(fileOpen)
        }
    }
}
